import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

export class ToastTypes {
  static show: string = 'show';
  static info: string = 'info';
  static success: string = 'success';
  static error: string = 'error';
  static warning: string = 'warning';
}

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private modals: any[] = [];

  constructor(private toastr: ToastrService) { }

  success(title: string, message?: string) {
    // customOptions.backgroundColor = '#2ecc71'
    // customOptions.color = 'green'
    // customOptions.icon = 'emoji-grinning'
    this.toastr.success(message, title);
  }

  info(title: string, message?: string) {
    // customOptions.backgroundColor = '#f1c40f'
    // customOptions.color = 'yellow'
    // customOptions.icon = 'emoji-grinning'
    this.toastr.info(message, title);
  }

  error(title: string, message?: string) {
    // customOptions.backgroundColor = '#e74c3c'
    // customOptions.color = 'red'
    // customOptions.icon = 'emoji-flushed'
    this.toastr.error(message, title);
  }

  warning(title: string, message?: string) {
    // customOptions.backgroundColor = '#e67e22'
    // customOptions.color = 'orange'
    // customOptions.icon = 'emoji-point-up'
    this.toastr.warning(message, title);
  }

  // button(type: 'error', title: string) {
  //   const options: IndividualConfig = {
  //     enableHtml: true,
  //     on: (toast) => {
  //       const value = toast.target.value
  //       if (value == 'yes') {
  //         console.log(toast.target.value, 'carai')
  //       }
  //       console.log(value)
  //     }
  //   }
  //   this.toastr[type]("<br /><button type='button' value='yes'>Ja</button><button type='button' value='no' >Nein</button>", title, options)
  // }

  hide(toast: any) {
    this.toastr.clear(toast);
    // this.iziToast.hide(toast);
  }

  addModal(modal: any) {
    this.modals.push(modal);
  }

  removeModal(id: string) {
    this.modals = this.modals.filter((x) => x.id !== id);
  }

  openModal(id: string) {
    const modal: any = this.modals.filter((x) => x.id === id)[0];
    modal.open();
  }

  closeModal(id: string) {
    const modal: any = this.modals.filter((x) => x.id === id)[0];
    modal.close();
  }


  prompt = (
    title: string,
    message?: string,
    okButtonText = 'Ok',
    dismissButtonText = 'Dismiss'
  ) =>
    new Promise(resolve => {
      const toastContainer = document.createElement('div');
      toastContainer.className = 'toast flex';
      toastContainer.style.zIndex = '100';
      const toastContent = document.createElement('div');
      toastContent.className = 'alert alert-info';

      const textContainer = document.createElement('div');

      const toastTitle = document.createElement('h4');
      toastTitle.innerText = title;
      textContainer.appendChild(toastTitle);

      if (message) {
        const toastMessage = document.createElement('span');
        toastMessage.innerText = message;
        textContainer.appendChild(toastMessage);
      }
      toastContent.appendChild(textContainer);

      const buttonContainer = document.createElement('div');
      const okButton = document.createElement('button');
      okButton.className = 'btn btn-white btn-sm mr-2';
      okButton.innerText = okButtonText;
      okButton.addEventListener('click', () => {
        // User clicked OK
        // Perform the action here
        resolve(true);
        document.body.removeChild(toastContainer);
      });
      const dismissButton = document.createElement('button');
      dismissButton.innerText = dismissButtonText;
      dismissButton.className = 'btn btn-white btn-outline btn-sm';
      dismissButton.addEventListener('click', () => {
        // User clicked Cancel
        // Handle the cancellation here
        resolve(false);
        document.body.removeChild(toastContainer);
      });
      buttonContainer.appendChild(okButton);
      buttonContainer.appendChild(dismissButton);
      toastContent.appendChild(buttonContainer);

      toastContainer.appendChild(toastContent);
      document.body.appendChild(toastContainer);
    });
}
