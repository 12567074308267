import { createFeature, createReducer, on } from '@ngrx/store';
import { PermissionGrantedActions } from './permission-granted.actions';
import { User_Permission } from 'src/app/shared/models';

export const permissionGrantedFeatureKey = 'permissionGranted';

export interface PermissionGrantedState {
  permissionsGranted: User_Permission[];
  permissionGranted: User_Permission | null;
  loading: boolean;
  error: any;
}

export const initialState: PermissionGrantedState = {
  permissionsGranted: [],
  permissionGranted: null,
  loading: true,
  error: null,
};

export const reducer = createReducer(
  initialState,
  on(
    PermissionGrantedActions.loadPermissionsGranted,
    (state): PermissionGrantedState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    PermissionGrantedActions.loadPermissionsGrantedSuccess,
    (state, { permissionsGranted }): PermissionGrantedState => ({
      ...state,
      permissionsGranted,
      // permissionsGranted: [
      //   ...state.permissionsGranted.filter(
      //     x =>
      //       !permissionsGranted.find(
      //         y => x.user?.id && x.user.id === y.user?.id
      //       )
      //   ),
      //   ...permissionsGranted,
      // ],
      loading: false,
      error: null,
    })
  ),
  on(
    PermissionGrantedActions.loadPermissionsGrantedFailure,
    (state, { error }): PermissionGrantedState => ({
      ...state,
      loading: false,
      error,
    })
  )
  // on(
  //   PermissionGrantedActions.loadPermissionGranted,
  //   (state): PermissionGrantedState => ({
  //     ...state,
  //     loading: true,
  //     error: null,
  //   })
  // ),
  // on(
  //   PermissionGrantedActions.loadPermissionGrantedSuccess,
  //   (state, { permissiongranted }): PermissionGrantedState => ({
  //     ...state,
  //     permissiongranteds: [permissiongranted],
  //     selectedPermissionGranted: permissiongranted,
  //     loading: false,
  //     error: null,
  //   })
  // ),
  // on(
  //   PermissionGrantedActions.loadPermissionGrantedFailure,
  //   (state, { error }): PermissionGrantedState => ({
  //     ...state,
  //     loading: false,
  //     error,
  //   })
  // ),
  // on(
  //   PermissionGrantedActions.addPermissionGranted,
  //   (state): PermissionGrantedState => ({
  //     ...state,
  //     loading: true,
  //     error: null,
  //   })
  // ),
  // on(
  //   PermissionGrantedActions.addPermissionGrantedSuccess,
  //   (state, { permissiongranted }): PermissionGrantedState => ({
  //     ...state,

  //     permissiongranteds: [...state.permissiongranteds, permissiongranted],

  //     loading: false,

  //     error: null,
  //   })
  // ),
  // on(
  //   PermissionGrantedActions.addPermissionGrantedFailure,
  //   (state, { error }): PermissionGrantedState => ({
  //     ...state,
  //     loading: false,
  //     error,
  //   })
  // ),
  // on(
  //   PermissionGrantedActions.removePermissionGranted,
  //   (state): PermissionGrantedState => ({
  //     ...state,
  //     loading: true,
  //     error: null,
  //   })
  // ),
  // on(
  //   PermissionGrantedActions.removePermissionGrantedSuccess,
  //   (state, { permissiongrantedId }): PermissionGrantedState => ({
  //     ...state,
  //     permissiongranteds: state.permissiongranteds.filter(
  //       permissiongranted => permissiongranted.id !== permissiongrantedId
  //     ),
  //     loading: false,
  //     error: null,
  //   })
  // ),
  // on(
  //   PermissionGrantedActions.removePermissionGrantedFailure,
  //   (state, { error }): PermissionGrantedState => ({
  //     ...state,
  //     loading: false,
  //     error,
  //   })
  // ),
  // on(
  //   PermissionGrantedActions.updatePermissionGranted,
  //   (state): PermissionGrantedState => ({
  //     ...state,
  //     loading: true,
  //     error: null,
  //   })
  // ),
  // on(
  //   PermissionGrantedActions.updatePermissionGrantedSuccess,
  //   (state, { permissiongranted }): PermissionGrantedState => ({
  //     ...state,
  //     permissiongranteds: state.permissiongranteds.map(item =>
  //       // item.id === permissiongranted.id ? permissiongranted : item
  //       item.id === permissiongranted.id ? { ...item, ...permissiongranted } : item
  //     ),
  //     loading: false,
  //     error: null,
  //   })
  // ),
  // on(
  //   PermissionGrantedActions.updatePermissionGrantedFailure,
  //   (state, { error }): PermissionGrantedState => ({
  //     ...state,
  //     loading: false,
  //     error,
  //   })
  // )
);

export const permissionGrantedFeature = createFeature({
  name: permissionGrantedFeatureKey,
  reducer,
});
